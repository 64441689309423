import './App.scss';
import andresImage from './assets/andres.jpeg';

function App() {
  return (
    <div className="App">
      <div className="Card-Shadow"></div>
      <div className="Card-Shadow-Second"></div>
      <div className="Card">
        <div className="Main-Page">
          <div className="Presentation">
            <span>Hello World! I'm</span>
            <span className="Name">Andrés <span style={{fontWeight: "bold"}}>Naranjo</span></span>
            <span>and I am a</span>
            <span className="Position">Software Developer</span>
          </div>
          <img className="Profile-Image" src={andresImage} alt="profile-pic" />
        </div>
      </div>
    </div>
  );
}

export default App;
